import { useState } from "react";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Checkbox,
    ListSubheader,
    Typography,
    Stack,
    Box,
    Slider,
} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


interface PriceFilterProps {
    onChange: (filters: number) => void;
}

const PriceFilter: React.FC<PriceFilterProps> = ({ onChange }) => {
    const [filters, setFilters] = useState<number>(0);
    const [arrow, setArrow] = useState<boolean>(true);

    const handleToggle = (value: number) => () => {
        if (filters == value) {
            setFilters(0);
            onChange(0);
            return;
        }
        setFilters(value);
        onChange(value);
    };

    return (
        <div>
            <List sx={{ borderBottom: { md: "1px solid lightgrey", xs: "", sm: "" } }}>
                <ListSubheader sx={{ backgroundColor: "transparent" }}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography fontWeight={500} fontSize={"1.5em"} color={"#000000"} marginRight={{ sm: "1em" }}>
                        PRICE
                        </Typography>
                        {arrow ? <KeyboardArrowDownIcon onClick={() => setArrow(false)} /> : <KeyboardArrowUpIcon onClick={() => setArrow(true)} /> }
                    </Box>
                </ListSubheader>
                {[
                    { name: "Under 500", value: 500 },
                    { name: "1000 to 3000", value: 1000 },
                ].map((val, idx) => (
                    <ListItem
                        
                        key={idx}
                        disableGutters
                        disablePadding
                        sx={{
                            display:`${arrow ?"flex":"none"}`,
                            width: "fit-content",
                            "& .MuiButtonBase-root.MuiListItemButton-root": {
                                py: 0.3,
                                "&:hover": {
                                    backgroundColor: "inherit !important",
                                },
                            },
                            "& .MuiListItemIcon-root": {
                                minWidth: "auto",
                            },
                            "& span": {
                                py: 0,
                            },
                        }}
                    >
                        <ListItemButton dense onClick={handleToggle(val?.value)} disableRipple>
                            <Checkbox
                                edge="start"
                                checked={filters == val.value}
                                disableRipple
                            />
                            <ListItemText
                                key={idx}
                                primary={
                                    <Typography p={0} color={"grey.main"} >
                                        {val.name}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default PriceFilter;
