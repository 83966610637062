import { createSlice } from '@reduxjs/toolkit'
import Data from "./data.json"
import type { PayloadAction } from '@reduxjs/toolkit'


interface FilterState {
    data: typeof Data;
    Brands: string[];
    Price: number;
    Rating: number;
}

const initialState: FilterState = { data: Data, Brands: [], Price: 0, Rating: 0 };

export const filterSlice = createSlice({
    name: "filter",
    initialState,
    reducers: {
        brand: (state: any, action: PayloadAction<string[]>) => {
            state.Brands = action.payload
            console.log(action.payload)
        },
        price: (state: any, action: PayloadAction<number>) => {
            state.Price = action.payload
            console.log(state.Price, action.payload)
        },
        rating: (state: any, action: PayloadAction<number>) => {
            state.Rating = action.payload
        },
        filter: (state: any, action: PayloadAction<number[]>) => {
            let filteredItems = [...initialState.data];
            state.Brands.length &&
                (filteredItems = filteredItems.filter(
                    (item) =>
                    {
                        console.log(item?.brand)
                        return state.Brands.indexOf(item?.brand) !== -1
                    }
                ));
            
            state.Price && (filteredItems = filteredItems.filter((item) => {
                if (state.Price == 500) {
                    return item.price < state.Price
                }
                if (state.Price == 1000) {
                    return item.price > state.Price
                }
                return item
            }))
            state.Rating && (filteredItems = filteredItems.filter((item) => {
                if (state.Rating) {
                    return item.rating == state.Rating
                }
                return item
            }))


            console.log(filteredItems)
            state.data = filteredItems;
        }
    }
})

export default filterSlice.reducer

export const filterActions = filterSlice.actions;