import { useEffect, useState } from "react"
import { Box,Grid, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import "../App.css"
import Cards from '../component/card';
import { useSelector, useDispatch } from 'react-redux';
import { filterActions } from '../store/filterSlice';
import BrandFilter from "../component/filters/brand";
import PriceFilter from "../component/filters/price";
import RatingFilter from "../component/filters/ratings";
import Empty from "../assest/empty.jpg"
interface RootState {
  filter: {
    data: {
      name: string,
      price: number,
      price1: number,
      rating: number,
      totalVotes: number,
      full: string
    }[]
  }
}

const Clothes = () => {

  const { data } = useSelector((state: RootState) => state.filter)
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<{
    BRAND: string[],
    PRICE: number
    RATING: number
  }>({
    BRAND: [],
    PRICE: 0,
    RATING: 0
  });

  const onBrandsChange = (brandsFilter: string[]) => {
    setFilters(
      (prev) =>
      (prev = {
        ...prev,
        BRAND: brandsFilter,
      })
    );
  };
  const onPriceChange = (priceFilters: number) => {
    setFilters(
      (prev) =>
      (prev = {
        ...prev,
        PRICE: priceFilters,
      })
    );
  };
  const onRatingChange = (priceFilters: number) => {
    setFilters(
      (prev) =>
      (prev = {
        ...prev,
        RATING: priceFilters,
      })
    );
  };

  useEffect(() => {
    dispatch(filterActions.brand(filters.BRAND));
    dispatch(filterActions.price(filters.PRICE));
    dispatch(filterActions.rating(filters.RATING));
    dispatch(filterActions.filter([3456789]))
  }, [filters])

  return (
    <Box>
      <Box width={"35%"} height={"3.2em"}
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          gap: "1em",
          padding: "0 .5em",
          borderRadius: "1em",
          justifyContent: "space-around",
          margin: "auto",
          marginTop: "4em",
          marginBottom: "2em",
          border: "1px solid grey"
        }}>
        <input type='text' className='search' placeholder='Search' />
        <SearchIcon sx={{ fontSize: "2em", color: "grey" }} />
      </Box>
      <Box display={"flex"} flexDirection={{ md: "row", sm: "column",xs:"column" }} px={"2em"} gap={"1em"} position={"relative"} >
        <Box display={"flex"} flexDirection={{md:"column",ms:"row",xs:"column"}} sx={{ width: {md:"18%",sm:"100%",xs:"100%"} }} >
          <Box position={{ md: "fixed", sm: "inherit" }}
            display={"flex"} flexDirection={{ md: "column", ms: "row" }}
            justifyContent={"space-between"}
            sx={{
              width: { md: "15%", sm: "100%",xs:"100%" },
              left: "2em",
              flexWrap: "wrap"
            }}>
            <Box width={{sm:"30%",md:"100%",xs:"30%"}}>
            <BrandFilter onChange={onBrandsChange} />
            </Box>
            <Box width={{ sm: "30%",md:"100%",xs:"35%" }}>
            <PriceFilter onChange={onPriceChange} />
            </Box>
            <Box width={{sm:"30%",md:"100%",xs:"40%"}}>
            <RatingFilter onChange={onRatingChange} />
            </Box>
          </Box>
        </Box>


        {data.length ? <Grid container display={"flex"} flexWrap={"wrap"}  justifyContent={"space-between"} width={{md:"80%",sm:"100%",xs:"100%"}}>
          {data.map((db: {
            name: string,
            price: number,
            price1: number,
            rating: number,
            totalVotes: number,
            full: string
          },idx:number) => (
            <Grid key={idx} item xs={10} sm={3.5} md={3.5} lg={2.7} mx={"auto"}  mt={"1em"}>
              <Cards name={db?.name} price={(db?.price)?.toString()} price1={(db?.price1)?.toString()} rating={db?.rating} votes={db?.totalVotes} image={db?.full} />
            </Grid>
          ))}

        </Grid> :
          <Box width={{ md: "80%", sm: "100%", xs: "100%" }} mt={"10em"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <img src={Empty} style={{ width: "18em", height: "100%" }} />
            <Typography fontSize={"1.2em"} >Try to change some filter</Typography>
          </Box>
        }
      </Box>
    </Box>
  )
}

export default Clothes;





