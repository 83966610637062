import { useState } from "react";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Checkbox,
    ListSubheader,
    Typography,
    Stack,
    Slider,
    Rating,
    Box
} from "@mui/material";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface PriceFilterProps {
    onChange: (filters: number) => void;
}

const RatingFilter: React.FC<PriceFilterProps> = ({ onChange }) => {
    const [filters, setFilters] = useState<number>(0);
    const [arrow, setArrow] = useState<boolean>(true);

    const handleToggle = (value: number) => () => {
        if (value == filters) {
            onChange(0);
            setFilters(0)
            return
        }
        onChange(value)
        setFilters(value);
    };

    return (
        <List>
            <ListSubheader sx={{ backgroundColor: "transparent" }}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontWeight={500} fontSize={"1.5em"} color={"#000000"}>
                        RATING
                    </Typography>
                    {arrow ? <KeyboardArrowDownIcon onClick={() => setArrow(false)} /> : <KeyboardArrowUpIcon onClick={() => setArrow(true)} />}
                </Box>
            </ListSubheader>
            {[5,4,3,2,1].map((val, idx) => (
                <ListItem
                    key={idx}
                    disableGutters
                    disablePadding
                    sx={{
                        display: `${arrow ? "flex" : "none"}`,
                        width: "fit-content",
                        "& .MuiButtonBase-root.MuiListItemButton-root": {
                            py: 0.3,
                            "&:hover": {
                                backgroundColor: "inherit !important",
                            },
                        },
                        "& .MuiListItemIcon-root": {
                            minWidth: "auto",
                        },
                        "& span": {
                            py: 0,
                        },
                    }}
                >
                    <ListItemButton dense onClick={handleToggle(val)} disableRipple>
                        <Checkbox
                            edge="start"
                            checked={filters == val}
                            disableRipple
                        />
                        <ListItemText
                            key={idx}
                            primary={
                                <Rating name="read-only" precision={0.1} color='primary.main' value={val} readOnly />
                            }
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default RatingFilter;
