import { Box } from '@mui/material';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Clothes from './pages/clothes';
function App() {

  // const color = faker.commerce.color();
  // const brandOptions = ["Mango","H&M","ZARA","GUCCI"]
  // const size = faker.datatype.number({ min: 0, max: 4 });
  // const sizes = faker.datatype.number({ min: 0, max: 3 });
  // const rating = faker.datatype.number({ min: 0, max: 5, precision: .5 });
  // const totalVotes = faker.datatype.number({ min: 100, max: 300, precision: 1 });
  // const brand = brandOptions[sizes]
  // const sizeOptions = ['XS', 'S', 'M', 'L', 'XL'];
  // const selectedSize = sizeOptions[size];
  // const materialOptions = ['cotton', 'wool', 'polyester', 'silk','nylon'];
  // const material = materialOptions[size];
  // const image = faker.image.fashion();
  // const price = faker.commerce.price(100, 3000);
  // console.log({ brand,price,selectedSize,rating,totalVotes})

  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/clothes' element={<Clothes />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

export default App;
