import { Box, Typography, Rating } from '@mui/material'
import { useState } from "react";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
type CardsProps = {
  name: string;
  price1: string ;
  price: string ;
  rating: number;
  votes: number ;
  image: string ;
};
const Cards = ({ name, price1, price, rating, votes, image }: CardsProps) => {
  const [favorite, setFavorite] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <Box width={"100%"} height={"100%"} display={"flex"}  flexDirection={"column"} gap={".6em"}>
      <Box width={"100%"} height={"80%"} position={"relative"}>
        <img onMouseOver={() => setShow(true)} onMouseLeave={() => setShow(false)} style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "7px", zIndex: "900",cursor:"pointer" }} src={image} />
        <Box position={"absolute"} zIndex={"990"} sx={{ top: "1em", right: "1em" }}>
          {favorite ? <FavoriteIcon onClick={() => setFavorite(false)} sx={{ color: "#D32524" }} /> : <FavoriteBorderIcon onClick={() => setFavorite(true)} sx={{ color: "#fff" }} />}
        </Box>
        <Box display={`${show ? "flex":"none"}`} position="absolute" sx={{ backgroundColor: "#6D84FF", borderBottomRightRadius: "7px", borderBottomLeftRadius: "7px", width: "100%", justifyContent: "center", color: "#fff", padding: ".6em 0", opacity: "0.8", bottom: "0em" }} >
          <Typography fontSize={"1.3em"} sx={{ opacity: "1" }} >View Product</Typography>
        </Box>
      </Box>
      <Typography fontSize={"1.1em"} fontWeight={500}>{name} </Typography>
      <Box display={"flex"} gap={".5em"}>
        <Typography sx={{ textDecoration: "line-through" }}>Rs.{price1} </Typography>
        <Typography fontSize={"1.1em"} fontWeight={600} sx={{ color: "#6D84FF" }}>Rs. {price}</Typography>
      </Box>
      <Box display={"flex"} gap={".3em"}>
        <Rating name="read-only" precision={0.1} color='primary.main' value={rating} readOnly />
        <Typography>({votes})</Typography>
      </Box>

     
    </Box>
  )
}

export default Cards