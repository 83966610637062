import { useState } from "react"
import { Box, Typography, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import "../App.css"
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();
  const [showtrends, setShowTrends] = useState(false);
  const [data, setdata] = useState([
    { name: "Shirt with puffed", image: "https://images.unsplash.com/photo-1633450797676-8ab93caab915?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb" },
    { name: "Linen jumpsuit", image: "https://images.unsplash.com/photo-1575483779815-e2e188ee69a1?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb" },
    { name: "White formal suit", image: "https://images.unsplash.com/photo-1635695524150-a7096538e19f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb" },
    { name: "Pattern dress", image: "https://images.unsplash.com/photo-1575483779815-e2e188ee69a1?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb" },
    { name: "Leather shirt dress", image: "https://images.unsplash.com/photo-1544264594-92340f695c7b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb" },

  ])

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      navigate("/clothes")
    }
  }
  return (
    <Box className="app">
      <Box width={{ lg: "35%", md: "40%", sm: "60%", xs: "80" }} height={"3.2em"}
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          gap: "1em",
          padding: "0 .5em",
          borderRadius: "1em",
          justifyContent: "space-around",
          margin: "auto",
          marginTop: "8em",
          marginBottom: "2em"
        }}>
        <input type='text' onFocus={() => setShowTrends(true)} onKeyPress={(e) => handleKeyPress(e)} className='search' placeholder='Search' />
        <SearchIcon sx={{ fontSize: "2em", color: "grey" }} />
      </Box>


      <Box
        onClick={() => { navigate("/clothes") }}
        sx={{
        backgroundColor: "#fff",
        margin: "auto",
        padding: "2em",
        display: `${showtrends ? "flex" : "none"}`,
        flexDirection: "column",
        gap: "1em"
      }}>
        <Typography variant="h6">Latest Trends</Typography>
        <Grid container display={"flex"} justifyContent={"space-between"} my={"2em"} gap={".5em"}>

          <Grid item lg={2} md={2.5} sm={3} display={"flex"} flexDirection={"column"} gap={".3em"} height={"15em"} >
            <img src={data[0].image} style={{ width: "100%", height: "100%", borderRadius: "7px", objectFit: "cover" }} />
            <Typography>{data[0].name} </Typography>
          </Grid>
          <Grid item lg={2} md={2.5} sm={3} display={"flex"} flexDirection={"column"} gap={".3em"} height={"15em"} >
            <img src={data[1].image} style={{ width: "100%", height: "100%", borderRadius: "7px", objectFit: "cover" }} />
            <Typography>{data[1].name} </Typography>
          </Grid>
          <Grid item lg={2} md={2.5} display={{ lg: "flex", md: "none", sm: "none", xs: "none" }} gap={".3em"} flexDirection={"column"} height={"15em"} >
            <img src={data[2].image} style={{ width: "100%", height: "100%", borderRadius: "7px", objectFit: "cover" }} />
            <Typography>{data[2].name} </Typography>
          </Grid>
          <Grid item lg={2} md={2.5} sm={3} display={{ lg: "flex", md: "flex", sm: "flex", xs: "none" }} gap={".3em"} flexDirection={"column"} height={"15em"} >
            <img src={data[3].image} style={{ width: "100%", height: "100%", borderRadius: "7px", objectFit: "cover" }} />
            <Typography>{data[3].name} </Typography>
          </Grid>
          <Grid item lg={2} md={2.5} display={{ md: "flex", sm: "none", xs: "none" }} flexDirection={"column"} gap={".3em"} height={"15em"} >
            <img src={data[4].image} style={{ width: "100%", height: "100%", borderRadius: "7px", objectFit: "cover" }} />
            <Typography>{data[4].name} </Typography>
          </Grid>

        </Grid>
        <Typography variant="h6">Popular suggestions</Typography>
        <Typography>Striped shirt dress</Typography>
        <Typography>Satin shirts</Typography>
        <Typography>Denim jumpsuit</Typography>
        <Typography>Leather dresses</Typography>
        <Typography>Solid tshirt</Typography>
      </Box>
    </Box>
  )
}

export default Home