import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Checkbox,
    ListSubheader,
    Typography,
    Box
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface BrandFilterProps {
    onChange: (filters: string[]) => void;
}

const BrandFilter: React.FC<BrandFilterProps> = ({ onChange }) => {
    const [filters, setFilters] = useState<string[]>([]);
    const [arrow, setArrow] = useState<boolean>(true);

    const handleToggle = (value: string) => () => {
        const currentIndex = filters.indexOf(value);
        const newFilters = [...filters];
        if (currentIndex === -1) {
            newFilters.push(value);
        } else {
            newFilters.splice(currentIndex, 1);
        }
        setFilters(newFilters);
        onChange(newFilters);
    };

    return (
        <div  >
            <List sx={{ borderBottom: {md:"1px solid lightgrey",xs:"",sm:""} }}>
                <ListSubheader sx={{ backgroundColor: "transparent" }}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography fontWeight={500} fontSize={"1.5em"} color={"#000000"} marginRight={{sm:"1em"}}>
                            BRANDS
                        </Typography>
                        {arrow ? <KeyboardArrowDownIcon onClick={() => setArrow(false)} /> : <KeyboardArrowUpIcon onClick={() => setArrow(true)} />}
                    </Box>
                </ListSubheader>
                {["Mango", "H&M", "ZARA", "GUCCI"].map((val, idx) => (
                    <ListItem
                        key={idx}
                        disableGutters
                        disablePadding
                        sx={{
                            display: `${arrow ? "flex" : "none"}`,
                            width: "fit-content",
                            "& .MuiButtonBase-root.MuiListItemButton-root": {
                                py: 0.3,
                                "&:hover": {
                                    backgroundColor: "inherit !important",
                                },
                            },
                            "& .MuiListItemIcon-root": {
                                minWidth: "auto",
                            },
                            "& span": {
                                py: 0,
                            },
                        }}
                    >
                        <ListItemButton dense onClick={handleToggle(val)} disableRipple>
                            <Checkbox
                                edge="start"
                                checked={filters.indexOf(val) !== -1}
                                disableRipple
                            />
                            <ListItemText
                                key={idx}
                                primary={
                                    <Typography
                                        p={0}
                                        color={"grey.main"}
                                        // fontWeight={600}
                                    >
                                        {val}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default BrandFilter;
